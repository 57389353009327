/* Copyright 2023 (Unpublished) Verto Inc. */

@import './variables.scss';

html,
body {
  font-size: 14px;
}

body {
  font-family: 'Poppins', sans-serif;
  background-color: $color-overall-background;
}

input[type='text'] {
  font-family: 'Poppins', sans-serif;
}

.p-label {
  > label {
    display: block;
    font-weight: normal;
    color: $color-text-primary;
  }
}

.small-header {
  font-size: 12px;
  color: grey;
  margin-bottom: -7px;
  margin-left: 15px;
}

.item {
  &-dropzone {
    @include card-styled-no-shadow();
    min-height: 60px;
    padding: 10px;
    margin: 5px;

    .cdk-drop-list-dragging {
      background: lightgreen;
    }

    .cdk-drag-placeholder {
      opacity: 0.5;
    }
  }
}

.p-autocomplete-input-token input {
  width: 20em;
}

.p-autocomplete-input-token input:disabled {
  width: 20em;
  &::-webkit-input-placeholder {
    color: transparent;
  }

  &::-moz-placeholder {
    /* Firefox 19+ */
    color: transparent;
  }

  &:-ms-input-placeholder {
    /* IE 10+ */
    color: transparent;
  }

  &:-moz-placeholder {
    /* Firefox 18- */
    color: transparent;
  }
}

// ============ Special Classes ===============

.no-padding {
  padding-top: 0;
  padding-bottom: 0;
}

.hidden {
  display: none !important;
}

.text-center {
  text-align: center !important;
}

.w-100 {
  width: 100%;
}

// ============= Mock Table ===============
.has-borders {
  border: 1px solid rgb(221, 221, 221);
}
